import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { isLoggedIn } from '../../modules/security/services/auth';
import { useCustomerContext } from '../../modules/customer/store';
import { ComplianceType } from '../../modules/customer/types/compliance';

function PrivateRoute({ children }: { children: JSX.Element }): JSX.Element {
  const customerContext = useCustomerContext();
  const params = useParams();

  const NavigateStatusPage = () => {
    return <Navigate to="/customer" />;
  };

  if (!isLoggedIn()) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (params?.type) {
    const complianceType = params?.type.toUpperCase() as ComplianceType;
    if (
      ![ComplianceType.INDIVIDUAL, ComplianceType.ORGANIZATION, ComplianceType.EMPLOYEE].includes(
        complianceType
      )
    ) {
      return NavigateStatusPage();
    }

    if (['APPROVED', 'REJECTED'].includes(customerContext.state.organizationCompliance)) {
      if ([ComplianceType.INDIVIDUAL, ComplianceType.ORGANIZATION].includes(complianceType)) {
        return NavigateStatusPage();
      }

      if (complianceType === ComplianceType.EMPLOYEE && !params?.id) {
        return NavigateStatusPage();
      }
    }
  }

  return children;
}

export default PrivateRoute;
