import React from 'react';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

interface Props {
  children: React.ReactNode;
}

const Recaptcha = ({ children }: Props) => {
  const key = process.env.REACT_APP_RECAPTCHA_KEY ?? '';

  return <GoogleReCaptchaProvider reCaptchaKey={key}>{children}</GoogleReCaptchaProvider>;
};

export default Recaptcha;
