import React from 'react';
import { Navigate } from 'react-router-dom';
import { isLoggedIn } from '../../../../security/services/auth';
import { useCustomerContext } from '../../../store';

function OrganizationPrivateRoute({ children }: { children: JSX.Element }): JSX.Element {
  const customerContext = useCustomerContext();

  if (!isLoggedIn()) {
    return <Navigate to="/login" state={{ from: location }} />;
  }
  if (customerContext.state.organizationCompliance !== 'APPROVED') {
    return <Navigate to="/" />;
  }

  return children;
}

export default OrganizationPrivateRoute;
