import React from 'react';
import { BounceLoader } from 'react-spinners';

const SuspenseLoading = () => {
  return (
    <div className="fixed w-screen h-screen z-200 top-0 left-0 flex justify-center items-center">
      <BounceLoader color="#F43F5E" loading={true} size={50} />
    </div>
  );
};

export default SuspenseLoading;
