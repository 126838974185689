import { BusinessType } from '../types/businessType';
import { ComplianceStatus } from '../types/compliance';
import { ConnectionStatus } from '../types/connection';
import { OrganizationType } from '../types/organizationType';

export interface ICustomerState {
  individualCompliance: ComplianceStatus;
  organizationCompliance: ComplianceStatus;
  connectionStatus?: ConnectionStatus;
  organizationTypes: OrganizationType[];
  businessTypes: BusinessType[];
}

const initialState: ICustomerState = {
  individualCompliance: 'NOT_SUBMITTED',
  organizationCompliance: 'NOT_SUBMITTED',
  organizationTypes: [],
  businessTypes: [],
};

export default initialState;
