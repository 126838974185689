import { IDocument } from './document';

export type ComplianceStatus =
  | 'NOT_SUBMITTED'
  | 'PENDING'
  | 'REJECTED'
  | 'PRE_REJECTED'
  | 'PRE_APPROVED'
  | 'APPROVED'
  | 'PROBLEMS_FOUND';

export type organizationTypes = 'COOPERATIVE' | 'MIPYME' | 'PDL';
export const businessOrganizationTypeMap = new Map<organizationTypes, string>();
businessOrganizationTypeMap.set('COOPERATIVE', 'Cooperative');
businessOrganizationTypeMap.set('MIPYME', 'MiPyme');
businessOrganizationTypeMap.set('PDL', 'PDL');

export interface IComplianceData {
  PersonalId: string;
  PersonalIdFile: FileList;
  AutonomousWorkerId: string;
  AutonomousWorkerIdFile: FileList;
}

export enum ComplianceType {
  INDIVIDUAL = 'INDIVIDUAL',
  ORGANIZATION = 'ORGANIZATION',
  EMPLOYEE = 'EMPLOYEE',
}

export interface ICompliance {
  requestId?: string;
  complianceType?: ComplianceType;
  status: ComplianceStatus;
  documents: IDocument[];
}

export interface IOrganizationEmployeeComplianceDTO {
  employeeId?: string;
  documents: IDocument[];
}

export interface IOrganizationEmployeeCompliance {
  parentId?: string;
  complianceType?: ComplianceType;
  status: ComplianceStatus;
  documents: IDocument[];
  date: string;
  organization?: IPartialOrganization;
}

export interface IPartialOrganization {
  legalName: string;
  organizationType: organizationTypes;
}
