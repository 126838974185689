import { createContext, Dispatch } from 'react';
import { TAction } from './actions';
import initialState, { ICustomerState } from './initialState';

interface IContextProps {
  state: ICustomerState;
  dispatch: Dispatch<TAction>;
}
const ComplianceContext = createContext<IContextProps>({
  state: initialState,
  dispatch: () => {},
});
export default ComplianceContext;
