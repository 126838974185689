import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { isLoggedIn } from '../../security/services/auth';
import { useCustomerContext } from '../store';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { ComplianceType } from '../types/compliance';

function CustomerCompliancePrivateRoute({ children }: { children: JSX.Element }): JSX.Element {
  const intl = useIntl();
  const customerContext = useCustomerContext();
  const params = useParams();

  const NavigateStatusPageError = () => {
    toast.error(
      intl.formatMessage({
        id: 'error_not_allowed_operation',
      })
    );
    return <Navigate to="/customer" />;
  };

  if (!isLoggedIn()) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (params?.type) {
    const complianceType = params?.type.toUpperCase() as ComplianceType;
    if (
      ![ComplianceType.INDIVIDUAL, ComplianceType.ORGANIZATION, ComplianceType.EMPLOYEE].includes(
        complianceType
      )
    ) {
      return NavigateStatusPageError();
    }

    //check if the compliance status of organization is APPROVED and the params:type is employee
    if (complianceType === ComplianceType.EMPLOYEE) {
      if (customerContext.state.organizationCompliance !== 'APPROVED') {
        return NavigateStatusPageError();
      }
      return children;
    }
  }

  if (
    customerContext.state.organizationCompliance !== 'NOT_SUBMITTED' ||
    customerContext.state.individualCompliance !== 'NOT_SUBMITTED'
  ) {
    return <Navigate to="/" />;
  }

  return children;
}

export default CustomerCompliancePrivateRoute;
