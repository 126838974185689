import React from 'react';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import ReactGA from 'react-ga4';
import './assets/css/base.css';

import './App.css';
import { GlobalProvider } from './core/stores/global';
import AppRoutes from './core/routes/Routes';
import RecaptchaProvider from './core/stores/recaptcha/RecaptchaProvider';

const appCache = createCache({
  key: 'acc',
});

function App() {
  ReactGA.initialize(process.env?.REACT_APP_GA_MEASURE_ID ?? '');
  ReactGA.send('pageview');
  return (
    <CacheProvider value={appCache}>
      <GlobalProvider>
        <RecaptchaProvider>
          <AppRoutes />
        </RecaptchaProvider>
      </GlobalProvider>
    </CacheProvider>
  );
}

export default App;
