import { TAction } from './actions';
import { ICustomerState } from './initialState';
import * as types from './types';

const reducer = (state: ICustomerState, action: TAction): ICustomerState => {
  const { type } = action;
  switch (type) {
    case types.SET_INDIVIDUAL_COMPLIANCE_STATUS:
      return { ...state, individualCompliance: action.payload };
    case types.SET_ORGANIZATION_COMPLIANCE_STATUS:
      return { ...state, organizationCompliance: action.payload };
    case types.SET_CONNECTION_STATUS:
      return { ...state, connectionStatus: action.payload };
    case types.SET_ORGANIZATION_TYPE:
      return { ...state, organizationTypes: action.payload };
    case types.SET_BUSINESS_TYPE:
      return { ...state, businessTypes: action.payload };
    default:
      return state;
  }
};
export default reducer;
