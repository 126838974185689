import React from 'react';
import { Route, Routes } from 'react-router-dom';
import SuspenseLoading from '../../components/commons/loading/SuspenseLoading';
import OrganizationPrivateRoute from '../../modules/customer/modules/organization/route/PrivateRoute';
import CustomerCompliancePrivateRoute from '../../modules/customer/route/PrivateRoute';
import PrivateRoute from './PrivateRoute';

const LoginPage = React.lazy(() => import('../../pages/login'));
const SignUpPage = React.lazy(() => import('../../pages/signup'));
const SignupResendLinkPage = React.lazy(() => import('../../pages/signupResendLink'));
const IndexPage = React.lazy(() => import('../../pages/index'));
const CustomerPage = React.lazy(() => import('../../pages/customer'));
const PricingPage = React.lazy(() => import('../../pages/pricing'));
const ConfirmAccountPage = React.lazy(() => import('../../pages/confirmAccount'));
const ResetPasswordPage = React.lazy(() => import('../../pages/resetPassword'));
const EmailResetPassword = React.lazy(() => import('../../pages/emailResetPassword'));
const TermOfServicesPage = React.lazy(() => import('../../pages/termsOfServices'));
const PrivacyPage = React.lazy(() => import('../../pages/privacy'));

const CustomerComplianceWizardPage = React.lazy(
  () => import('../../pages/customerComplianceWizard')
);
const CustomerIndexPage = React.lazy(() => import('../../modules/customer/container/customer'));

const CustomerComplianceReviewIndex = React.lazy(
  () => import('../../modules/customer/modules/compliance/container/CustomerComplianceReviewIndex')
);
const CustomerOrganizationIndex = React.lazy(
  () => import('../../modules/customer/modules/organization/container/OrganizationIndex')
);

function AppRoutes() {
  return (
    <React.Suspense fallback={<SuspenseLoading />}>
      <Routes>
        <Route path="/" element={<IndexPage />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/signupResendLink" element={<SignupResendLinkPage />} />
        <Route
          path="/api/AccountConfirmation/VerifyEmailByToken"
          element={<ConfirmAccountPage />}
        />
        <Route path="/resetPassword" element={<ResetPasswordPage />} />
        <Route path="/emailResetPassword" element={<EmailResetPassword />} />
        <Route path="/terms_of_service" element={<TermOfServicesPage />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        {/* Private Route */}

        <Route
          path="/customer"
          element={
            <PrivateRoute>
              <CustomerPage />
            </PrivateRoute>
          }
        >
          <Route index element={<CustomerIndexPage />} />
          <Route
            path="compliance/:type"
            element={
              <CustomerCompliancePrivateRoute>
                <CustomerComplianceWizardPage />
              </CustomerCompliancePrivateRoute>
            }
          />
          <Route path="compliance/review/:type">
            <Route
              path=""
              element={
                <PrivateRoute>
                  <CustomerComplianceReviewIndex />
                </PrivateRoute>
              }
            />
            <Route
              path=":id"
              element={
                <PrivateRoute>
                  <CustomerComplianceReviewIndex />
                </PrivateRoute>
              }
            />
          </Route>
          <Route
            path="organization"
            element={
              <OrganizationPrivateRoute>
                <CustomerOrganizationIndex />
              </OrganizationPrivateRoute>
            }
          />
        </Route>

        <Route path="*" element={<>Not Found</>} />
      </Routes>
    </React.Suspense>
  );
}

export default AppRoutes;
