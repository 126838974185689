export const SET_INDIVIDUAL_COMPLIANCE_STATUS = 'SET_INDIVIDUAL_COMPLIANCE_STATUS';
export type SET_INDIVIDUAL_COMPLIANCE_STATUS = typeof SET_INDIVIDUAL_COMPLIANCE_STATUS;

export const SET_ORGANIZATION_COMPLIANCE_STATUS = 'SET_ORGANIZATION_COMPLIANCE_STATUS';
export type SET_ORGANIZATION_COMPLIANCE_STATUS = typeof SET_ORGANIZATION_COMPLIANCE_STATUS;

export const SET_CONNECTION_STATUS = 'SET_CONNECTION_STATUS';
export type SET_CONNECTION_STATUS = typeof SET_CONNECTION_STATUS;

export const SET_ORGANIZATION_TYPE = 'SET_ORGANIZATION_TYPE';
export type SET_ORGANIZATION_TYPE = typeof SET_ORGANIZATION_TYPE;

export const SET_BUSINESS_TYPE = 'SET_BUSINESS_TYPE';
export type SET_BUSINESS_TYPE = typeof SET_BUSINESS_TYPE;
