import { ILoggedUser } from '../../types/user';

export interface IGlobalState {
  isAuthenticated: boolean;
  loading: boolean;
  loadingMessage?: string;
  user?: ILoggedUser | undefined;
  error: string | undefined;
}

const initialState: IGlobalState = {
  isAuthenticated: false,
  loading: false,
  loadingMessage: '',
  user: undefined,
  error: undefined,
};

export default initialState;
