import { handleLogin, logout } from '../../../modules/security/services/auth';
import { TAction } from './actions';
import { IGlobalState } from './initialState';
import * as types from './types';

const reducer = (state: IGlobalState, action: TAction): IGlobalState => {
  const { type } = action;
  switch (type) {
    case types.MAKE_REQUEST:
      return { ...state, loading: true, loadingMessage: action?.payload };
    case types.LOGIN_SUCCESS:
      void handleLogin(action?.payload);
      return {
        ...state,
        loading: false,
        loadingMessage: '',
        user: action?.payload,
        isAuthenticated: true,
      };

    case types.REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        loadingMessage: '',
        error: action?.payload,
      };
    case types.CLEAR_ERROR:
      return {
        ...state,
        error: undefined,
      };
    case types.LOGOUT:
      logout();
      return { ...state, isAuthenticated: false };
    case types.LOADING:
      return {
        ...state,
        loading: action?.payload,
        ...(!action?.payload && {
          loadingMessage: '',
        }),
      };
    default:
      return state;
  }
};
export default reducer;
