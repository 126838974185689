export const MAKE_REQUEST = 'MAKE_REQUEST';
export type MAKE_REQUEST = typeof MAKE_REQUEST;
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export type LOGIN_SUCCESS = typeof LOGIN_SUCCESS;
export const REQUEST_ERROR = 'REQUEST_ERROR';
export type REQUEST_ERROR = typeof REQUEST_ERROR;
export const CLEAR_ERROR = 'CLEAR_ERROR';
export type CLEAR_ERROR = typeof CLEAR_ERROR;
export const LOGOUT = 'LOGOUT';
export type LOGOUT = typeof LOGOUT;
export const LOADING = 'LOADING';
export type LOADING = typeof LOADING;
